<template>
  <Icon :icon="mapActionIcon[action]" class="PropertyActionIndicator" />
</template>

<script setup lang="ts">
export type Action = "edit" | "open" | "select" | "plus";

defineProps<{ action: Action }>();

const mapActionIcon: Record<Action, string> = {
  edit: "lucide:pencil",
  open: "lucide:arrow-up-right",
  select: "lucide:chevron-down",
  plus: "lucide:plus",
};
</script>

<style lang="scss" scoped>
.PropertyActionIndicator {
  color: var(--gray-3);
}
</style>
